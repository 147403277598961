.pagination{display: inline-block;padding-left: 0;border-radius: @radius;font-size: @font-size;}
.pagination > li{display: inline;}
.pagination > li > a,.pagination > li > span{line-height: 1.42857143;position: relative;float: left;margin-left: -1px;padding: 8px 15px;text-decoration: none;color: @primary;border: 1px solid @border;background-color: @light;}
.pagination > li:first-child > a,.pagination > li:first-child > span{margin-left: 0;border-top-left-radius: @radius;border-bottom-left-radius: @radius;}
.pagination > li:last-child > a,.pagination > li:last-child > span{border-top-right-radius: @radius;border-bottom-right-radius: @radius;}
.pagination > li > a:focus,.pagination > li > a:hover,.pagination > li > span:focus,.pagination > li > span:hover{color: @primary;background-color: @gray-lighter;}
.pagination > .active > a,.pagination > .active > a:focus,.pagination > .active > a:hover,.pagination > .active > span,.pagination > .active > span:focus,.pagination > .active > span:hover{
	z-index: 2;cursor: default;color: @light;border-color: @primary;background-color: @primary;}
.pagination > .disabled > a,.pagination > .disabled > a:focus,.pagination > .disabled > a:hover,.pagination > .disabled > span,.pagination > .disabled > span:focus,.pagination > .disabled > span:hover{
	cursor: not-allowed;color: #777;border-color: #ddd;background-color: @light;}
.pagination-lg > li > a,.pagination-lg > li > span{font-size: @font-size-lg;line-height: 1.3333333;padding: 10px 16px;}
.pagination-lg > li:first-child > a,.pagination-lg > li:first-child > span{
	border-top-left-radius: @radius + 2px;border-bottom-left-radius: @radius + 2px;}
.pagination-lg > li:last-child > a,.pagination-lg > li:last-child > span{border-top-right-radius: @radius + 2px;border-bottom-right-radius: @radius + 2px;}
.pagination-sm > li > a,.pagination-sm > li > span{font-size: @font-size-sm;line-height: 1.5;padding: 5px 10px;}
.pagination-sm > li:first-child > a,.pagination-sm > li:first-child > span{border-top-left-radius: @radius - 1px;border-bottom-left-radius: @radius - 1px;}
.pagination-sm > li:last-child > a,.pagination-sm > li:last-child > span{border-top-right-radius: @radius - 1px;border-bottom-right-radius: @radius - 1px;}
.pagination > li > .unable, .pagination > .unable:hover{cursor: default;color: #ccc;background: @light;}