.dl dt{
    font-weight: 700;
    padding: 0.2em 0.5em;
}
.dl dd{
    padding: 0.2em 0.5em;
}
.dl-table dt{
    float: left;
    clear: left;
    overflow: hidden;
    width: @dl-label-width;
    padding-left: 0;
	padding-right: 0;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.dl-table dd{
    margin-left: @dl-label-width + 1em;
    padding-left: 0;
	padding-right: 0;
}
