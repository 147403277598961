html,body{
    height: 100%;
}
.g-side{
    position: fixed;
    z-index: 92;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 230px;
    height: 100%;
}
.g-menu{height: 100%;}
.menu-ui{
    font-size:16px;
    border-top: 1px solid #434343;
    border-radius: 0;
}
.menu-ui .menu-sub-group .menu-item a{
    display: block;
}
.g-head{
    position: fixed;
    z-index: 91;
    top: 0;
    left: 0;
    line-height: 50px;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    padding-left: 250px;
    background-color: @light;
}
.logo{
    font-size: 18px;
    height: 99px;
    line-height: 99px;
    text-align: center;
    color: @light;
    background: @gray-dark;
    margin-bottom: 0;
}
.logo img{
    width: 2em;
    margin-left: -.5em;
    margin-right: .5em;
    vertical-align: middle;
}
.nav-bar{
    float: right;
}
