.m{margin:@space;}
.m-0{margin:0;}
.m-l{margin-left: @space;}
.m-t{margin-top: @space;}
.m-r{margin-right: @space;}
.m-b{margin-bottom: @space;}
.m-lr{margin-left: @space;margin-right: @space;}
.m-tb{margin-top: @space;margin-bottom: @space;}
.m-sm{margin:@space-sm;}
.m-l-sm{margin-left: @space-sm;}
.m-t-sm{margin-top: @space-sm;}
.m-r-sm{margin-right: @space-sm;}
.m-b-sm{margin-bottom: @space-sm;}
.m-lr-sm{margin-left: @space-sm;margin-right: @space-sm;}
.m-tb-sm{margin-top: @space-sm;margin-bottom: @space-sm;}
.m-lg{margin: @space-lg;}
.m-l-lg{margin-left: @space-lg;}
.m-t-lg{margin-top: @space-lg;}
.m-r-lg{margin-right: @space-lg;}
.m-b-lg{margin-bottom: @space-lg;}
.m-lr-lg{margin-left: @space-lg;margin-right: @space-lg;}
.m-tb-lg{margin-top: @space-lg;margin-bottom: @space-lg;}
.p{padding:@space;}
.p-0{padding:0;}
.p-l{padding-left: @space;}
.p-t{padding-top: @space;}
.p-r{padding-right: @space;}
.p-b{padding-bottom: @space;}
.p-lr{padding-left: @space;padding-right: @space;}
.p-tb{padding-top: @space;padding-bottom: @space;}
.p-sm{padding:@space-sm;}
.p-l-sm{padding-left: @space-sm;}
.p-t-sm{padding-top: @space-sm;}
.p-r-sm{padding-right: @space-sm;}
.p-b-sm{padding-bottom: @space-sm;}
.p-lr-sm{padding-left: @space-sm;padding-right: @space-sm;}
.p-tb-sm{padding-top: @space-sm;padding-bottom: @space-sm;}
.p-lg{padding: @space-lg;}
.p-l-lg{padding-left: @space-lg;}
.p-t-lg{padding-top: @space-lg;}
.p-r-lg{padding-right: @space-lg;}
.p-b-lg{padding-bottom: @space-lg;}
.p-lr-lg{padding-left: @space-lg;padding-right: @space-lg;}
.p-tb-lg{padding-top: @space-lg;padding-bottom: @space-lg;}
