@font-face {font-family: "flow-icon";
  src: url('../font/iconfont.eot'); /* IE9*/
  src: url('../font/iconfont.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../font/iconfont.woff') format('woff'), /* chrome、firefox */
  url('../font/iconfont.ttf') format('truetype'), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url('../font/iconfont.svg#iconfont') format('svg'); /* iOS 4.1- */
}
.ion {
  font-family:"flow-icon" !important;
  display:inline-block;
  line-height: 1;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  vertical-align: middle;   
}