.bread{
    font-size: @font-size;
    color: @text-color;
}
.bread > .bread-sep{
    margin: 0 .5em;
    color: @gray-light;
}
.bread > span.bread-link{
    font-weight: 700;
}
