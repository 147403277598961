
.card{display: block; font-size: @font-size;position: relative; overflow: hidden; transition: all .2s ease-in-out;border-radius: @radius;background: @light;}
.card:hover{border-color: @gray-lighter;box-shadow: 0 1px 6px rgba(0, 0, 0, .2);}
.card-shadow{border-color: @gray-lighter;box-shadow: 0 1px 6px rgba(0, 0, 0, .2);}
.card-bordered{border: 1px solid @border;}
.card-head{position: relative;line-height: 1;line-height: @form-base-height;height: @form-base-height;padding: 4px @space;border-bottom: 1px solid @border;color: @gray;}
.card-title{ font-weight: 700;display: inline-block;overflow: hidden;width: 100%;white-space: nowrap;text-overflow: ellipsis;}
.card-extra{position: absolute;top: 4px;right: @space;}
.card-body{padding: @space;}
.card-foot{padding: 4px @space;background-color: @gray-lighter; border-top: 1px solid @border;overflow: hidden;}
.card.card-primary .card-head, .card.card-primary .card-foot{background: @primary;color:@light;border-color: @primary-dark; }
.card.card-primary.card-inverse .card-head, .card.card-primary.card-inverse .card-foot{background: @primary-dark; border-color: @primary; }
.card.card-primary.card-inverse .card-body{background: @primary;color:@light;}
.card.card-auxiliary .card-head, .card.card-auxiliary .card-foot{background: @auxiliary;color:@light;border-color: @auxiliary-dark; }
.card.card-auxiliary.card-inverse .card-head, .card.card-auxiliary.card-inverse .card-foot{background: @auxiliary-dark; border-color: @auxiliary; }
.card.card-auxiliary.card-inverse .card-body{background: @auxiliary;color:@light;}
.card.card-info .card-head, .card.card-info .card-foot{background: @info;color:@light;border-color: @info-dark; }
.card.card-info.card-inverse .card-head, .card.card-info.card-inverse .card-foot{background: @info-dark; border-color: @info; }
.card.card-info.card-inverse .card-body{background: @info;color:@light;}
.card.card-success .card-head, .card.card-success .card-foot{background: @success;color:@light;border-color: @success-dark; }
.card.card-success.card-inverse .card-head, .card.card-success.card-inverse .card-foot{background: @success-dark; border-color: @success; }
.card.card-success.card-inverse .card-body{background: @success;color:@light;}
.card.card-warning .card-head, .card.card-warning .card-foot{background: @warning;color:@light;border-color: @warning-dark; }
.card.card-warning.card-inverse .card-head, .card.card-warning.card-inverse .card-foot{background: @warning-dark; border-color: @warning; }
.card.card-warning.card-inverse .card-body{background: @warning;color:@light;}
.card.card-danger .card-head, .card.card-danger .card-foot{background: @danger;color:@light;border-color: @danger-dark; }
.card.card-danger.card-inverse .card-head, .card.card-danger.card-inverse .card-foot{background: @danger-dark; border-color: @danger; }
.card.card-danger.card-inverse .card-body{background: @danger;color:@light;}
