@import '__dev/css/config.less';
//组件
@import '_component/reset.less';
@import '_component/badge.less';
@import '_component/bread.less';
@import '_component/btn.less';
@import '_component/card.less';
@import '_component/color.less';
@import '_component/dl.less';
@import '_component/flex.less';
@import '_component/form.less';
@import '_component/grid.less';
@import '_component/iconfont.less';
@import '_component/iconsprite.less';
@import '_component/item.less';
@import '_component/label.less';
@import '_component/menu.less';
@import '_component/page.less';
@import '_component/progress.less';
@import '_component/rect.less';
@import '_component/space.less';
@import '_component/spin.less';
@import '_component/switch.less';
@import '_component/tab.less';
@import '_component/table.less';
@import '_component/tools.less';
@import '_component/tree.less';
//@import "__dev/css/responsive.less";
.g-body{
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    box-sizing: border-box;
    height: 100%;
    padding: 100px 0 2em 230px;
    background: #f5f5f5;
}
.g-statues-bar{
    position: fixed;
    z-index: 93;
    top: 50px;
    left: 0;
    line-height: 50px;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    margin-left: 230px;

    border-bottom: 1px solid @gray-light;
    background: #f5f5f5;
}

.g-center{
    display: table-cell;

    border: 1px solid @border;
    vertical-align: middle;
}
.loginWrapper{border:1px solid @border;}