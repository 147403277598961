
.form-control { line-height:@form-base-height; height:@form-base-height; padding: 0 @form-base-space; display: block; width: 100%; 
  box-sizing:border-box;vertical-align: middle;transition: border-color ease-in-out 0.1s; color: @gray; border: 1px solid @border; 
  background: @light; border-radius: @radius;}
.form-control:focus { border-color: @primary; outline: 0;}
.form-control-blank { border-width: 0 0 1px 0;background:none;border-radius: 0;}
.form-control-blank:focus{box-shadow: none;}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control { opacity: 1; background-color: #eee;}
.form-control[disabled], fieldset[disabled] .form-control { cursor: not-allowed;}
select.form-control{min-width: 4em;}
input[type='search'] { appearance: none;}
textarea.form-control { height: @line-height * 5 * @font-size;line-height: @line-height;}
textarea.input-lg{height: @line-height * 8 * @font-size-lg;font-size:@font-size-lg;}
textarea.input-sm{height: @line-height * 3 * @font-size-sm;font-size:@font-size-sm;}
.form-group { margin-bottom: @space-sm;}

.radio, .checkbox { position: relative; display: block; line-height: normal;padding-left: 20px;margin-top: @space-sm;margin-bottom: @space-sm;cursor: pointer; }
.radio +.radio, .checkbox +.checkbox { margin-top: -@space-sm / 2;}
.form-group .radio, .form-group .checkbox{margin-top: 0; margin-bottom: 0;}
.radio input[type='radio'], .checkbox input[type='checkbox']{ 
  float: left; margin-left: -20px;}
.radio-inline, .checkbox-inline, .form-inline .radio, .form-inline .checkbox { display: inline-block; margin-top: 0;margin-bottom: 0; vertical-align: middle;}
.radio-inline +.radio-inline, .checkbox-inline +.checkbox-inline,.form-inline .radio+.radio, .form-inline .checkbox+.checkbox { margin-top: 0; margin-left: @form-base-space;}
.radio.disabled, .checkbox.disabled, fieldset[disabled] .radio, fieldset[disabled] .checkbox, input[type='radio'][disabled], input[type='checkbox'][disabled] { cursor: not-allowed;}
/* radio & check runtime */
.radio.runtime input[type='radio'], .checkbox.runtime input[type='checkbox']{
	visibility: hidden;
}
.radio.runtime:before, .checkbox.runtime:before, .radio.runtime:after, .checkbox.runtime:after{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
}
.checkbox.runtime:before, .radio.runtime:before{
	top:50%;
	width: 14px;
    height: 14px;
    margin: -8px 4px 0 0;

    border: 1px solid #d7dde4;
    border-radius: 2px;
    background: @light;}
.checkbox.runtime:after{
    top: 50%;
    left: 5px;
    width: 4px;
    height: 8px;
	margin-top: -6px;
    transition: transform 0.2s ease-in-out;
    transform: rotate(0deg) scale(0);

    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
}
.checkbox.checked.runtime:before{
	border-color: @primary;
    background-color: @primary;
}
.checkbox.checked.runtime:after{
	transform: rotate(45deg) scale(1);
}
.radio.runtime:before{
	border-radius: 8px;
	border-color: @primary;
}
.radio.disabled:before{
  border-color: #d7dde4;
}
.radio.runtime:after{
	top:50%;
    left: 4px;
    width: 8px;
	height: 8px;
    border-radius: 4px;
    background: @primary;
    margin-top: -4px;
	transition: transform 0.2s ease-in-out;
	transform: rotate(0deg) scale(0);
	visibility: hidden;
}
.radio.checked.runtime:after{
	transform: rotate(0deg) scale(1);
	visibility: visible;
}

.form-control-static { height:@form-base-height;line-height:@form-base-height;}
.form-control-static.input-lg, .form-control-static.input-sm { padding-right: 0; padding-left: 0;}
.help-block { display: block; margin-top: @form-base-space; margin-bottom: @line-height / 2 * @font-size; color: @gray-light;}
.form-mark { display: inline-block; margin: 0 0.3em;color:@danger;vertical-align: text-top;line-height: normal;}
big.form-mark { font-size: 1.5em;}

.input-sm { font-size: @font-size-sm; height: @form-sm-height; line-height: @form-sm-height; }
.form-group-sm .form-control { font-size: @font-size-sm; line-height: @form-sm-height; height: @form-sm-height; padding: 5px @form-base-space;}
.form-group-sm .form-control-static { font-size: @font-size-sm; line-height: @form-sm-height; height: @form-sm-height; min-height: @form-sm-height; padding: 5px @form-base-space;}
.input-lg { font-size: @font-size-lg; line-height:  @form-lg-height; height:  @form-lg-height; }
.form-group-lg .form-control { font-size: @font-size-lg; height: @form-lg-height; padding:@line-height / 5.5 * @font-size @form-base-space;}
.form-group-lg .form-control-static { font-size: @font-size-lg; line-height: 1.3333333; height:  @form-lg-height; min-height:@form-base-height; padding: @form-base-space 16px;}

.has-feedback { position: relative;}
.has-feedback .form-control { padding-right: @form-base-height;}
.has-feedback .input-group .form-control{padding-right: 0;} 
.form-group .form-control-feedback { line-height:@form-base-height; position: absolute; z-index: 2; top: 0; right: 0; display: block; width:@form-base-height; height:@form-base-height; text-align: center; pointer-events: none;}
.has-feedback .input-group .form-control{padding-left: @form-base-height;}
.form-group-lg .form-control-feedback, .input-lg +.form-control-feedback { line-height:  @form-lg-height; width:  @form-lg-height; height:  @form-lg-height;}
.form-group-sm .form-control-feedback, .input-sm +.form-control-feedback { line-height: @form-sm-height; width: @form-sm-height; height: @form-sm-height;}
.has-feedback .form-control-feedback { right: 2%;}

.has-primary .help-block, .has-primary .control-label, .has-primary .radio, .has-primary .checkbox{ color: @primary;}
.has-primary .form-control { border-color: @primary;}
.has-primary .form-control:focus { border-color: @primary-dark;}
.has-primary .input-group-addon { color: @light; border-color: @primary; background-color: @primary;}
.has-primary .form-control-feedback { color: @primary;}

.has-auxiliary .help-block, .has-auxiliary .control-label, .has-auxiliary .radio, .has-auxiliary .checkbox{ color: @auxiliary;}
.has-auxiliary .form-control { border-color: @auxiliary;}
.has-auxiliary .form-control:focus { border-color: @auxiliary-dark;}
.has-auxiliary .input-group-addon { color: @light; border-color: @auxiliary; background-color: @auxiliary;}
.has-auxiliary .form-control-feedback { color: @auxiliary;}

.has-info .help-block, .has-info .control-label, .has-info .radio, .has-info .checkbox{ color: @info;}
.has-info .form-control { border-color: @info;}
.has-info .form-control:focus { border-color: @info-dark;}
.has-info .input-group-addon { color: @light; border-color: @info; background-color: @info;}
.has-info .form-control-feedback { color: @info;}

.has-success .help-block, .has-success .control-label, .has-success .radio, .has-success .checkbox{ color: @success;}
.has-success .form-control { border-color: @success;}
.has-success .form-control:focus { border-color: @success-dark;}
.has-success .input-group-addon { color: @light; border-color: @success; background-color: @success;}
.has-success .form-control-feedback { color: @success;}
.has-warning .help-block, .has-warning .control-label, .has-warning .radio, .has-warning .checkbox{ color: @warning;}
.has-warning .form-control { border-color: @warning; }
.has-warning .form-control:focus { border-color:@warning-dark; }
.has-warning .input-group-addon { color: @warning; border-color: @warning; background-color: @warning;}
.has-warning .form-control-feedback { color: @warning;}
.has-danger .help-block, .has-danger .control-label, .has-danger .radio, .has-danger .checkbox{ color: @danger;}
.has-danger .form-control { border-color: @danger;}
.has-danger .form-control:focus { border-color: @danger-dark;}
.has-danger .input-group-addon { color: @danger; border-color: @danger; background-color: @danger;}
.has-danger .form-control-feedback { color: @danger;}

.form-inline .form-group .help-block { display: inline-block; margin: 0 1em; vertical-align: middle;}
.form-inline .form-control { display: inline-block; width: auto; vertical-align: middle;}
.form-inline .form-control-static { display: inline-block;margin:0 1em;}
.form-inline .input-group { display: inline-table; vertical-align: middle;width:auto;}
.form-inline .input-group .input-group-btn, .form-inline .input-group .form-control { width: auto;}
.form-inline .input-group >.form-control { width: 100%;}
.form-inline .control-label {display: inline-block; margin-bottom: 0; vertical-align: middle;min-width: 5em;}
.form-inline .has-feedback .form-control-feedback { top: 0;}

.control-label { margin-bottom: 0; height:@form-base-height;line-height:@form-base-height;overflow: hidden; text-align: right;}
.form-group-lg .control-label {font-size:@font-size-lg; height: @form-lg-height;line-height: @form-lg-height;}
.form-group-sm .control-label {font-size:@font-size-sm; height: @form-sm-height;line-height: @form-sm-height;}

.input-group {display: table; width:100%; position: relative; overflow: hidden; border-collapse: separate;;}
.input-group[class*='col-'] { float: none; padding-right: 0; padding-left: 0;}
.input-group-lg >.form-control, .input-group-lg >.input-group-addon, .input-group-lg >.input-group-btn >.btn { font-size: @font-size-lg; line-height: 1.3333333; height: @form-lg-height; padding: @form-base-space 16px; }
.input-group-sm >.form-control, .input-group-sm >.input-group-addon, .input-group-sm >.input-group-btn >.btn { font-size: @font-size-sm; line-height: 1.5; height: @form-sm-height; padding: 0 @form-base-space; }
.input-group-sm >.input-group-addon{width:@form-sm-height; height: @form-sm-height - 2;padding: 0;}
.input-group-lg >.input-group-addon{ height: @form-lg-height - 2;padding-top: 0;padding-bottom: 0;}

.input-group-addon{ border-radius: @radius; font-size: @font-size; font-weight: normal; text-align: center; background-color: @gray-lighter;
  color: @gray; border: 1px solid @border; border-left-width: 0;border-right-width:0; min-width:@form-base-height;box-sizing: content-box;
height:@form-base-height - 2;line-height:@form-base-height - 2;border-right-width:1px\9;}
.input-group-addon.noborder{border:0;}
.input-group-lg>.input-group-addon.noborder{padding:0;}
.input-group-addon, .input-group-btn, .input-group .form-control { display: table-cell;}
.input-group-addon:not(:first-child):not(:last-child), .input-group-btn >.btn:not(:first-child):not(:last-child), .input-group .form-control:not(:first-child):not(:last-child) { 
	border-radius: 0;
}
.input-group-addon .btn{vertical-align: top;}
.input-group-addon, .input-group-btn { vertical-align: middle; white-space: nowrap;}
.input-group-addon.input-sm { font-size: @font-size-sm; padding: 5px @form-base-space; }
.input-group-addon.input-lg { font-size: @font-size-lg; padding: @form-base-space; }
.input-group-addon input[type='radio'], .input-group-addon input[type='checkbox'] { margin-top: 0;}
.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn >.btn:first-child { 
  border-top-right-radius: 0; border-bottom-right-radius: 0;}
.input-group-addon:first-child { border-left-width: 1px;}
.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn >.btn:last-child{
 border-top-left-radius: 0; border-bottom-left-radius: 0;
}
.input-group-addon:last-child { border-right-width: 1px;}
.input-group-addon:last-child .btn{border-radius: 0 @radius @radius 0;}

.input-group-btn { font-size: @font-size; position: relative; white-space: nowrap;font-size:0}
.input-group-btn >.btn { position: relative;}
.input-group-btn >.btn +.btn { margin-left: -1px;}
.input-group-btn >.btn:hover, .input-group-btn >.btn:focus, .input-group-btn >.btn:active { z-index: 2;}
.input-group-btn:first-child >.btn{ margin-right: -1px;}
.input-group-btn:last-child >.btn{ margin-left: -1px;}
