
.text-muted{
    color: @gray-light;
}
.text-black{
    color: @black;
}
.text-light{
    color: @light;
}
.text-primary,.btn-ghost.btn-primary{
    color: @primary;
}
a.text-primary:hover{
    color: @primary-dark;
}
.text-auxiliary,.btn-ghost.btn-auxiliary{
    color: @auxiliary;
}
a.text-auxiliary:hover{
    color: @auxiliary-dark;
}
.text-success,.btn-ghost.btn-success{
    color: @success;
}
a.text-success:hover{
    color: @success-dark;
}
.text-info,.btn-ghost.btn-info{
    color: @info;
}
a.text-info:hover{
    color: @info-dark;
}
.text-warning,.btn-ghost.btn-warning{
    color: @warning;
}
a.text-warning:hover{
    color: @warning-dark;
}
.text-danger,.btn-ghost.btn-danger{
    color: @danger;
}
a.text-danger:hover{
    color: @danger-dark;
}

.bg-black{
    background-color: @black;
}
.bg-light{
    background-color: @light;
}
.bg-gray-light{
    background-color: @gray-light;
}
.bg-gray-lighter{
    background-color: @gray-lighter;
}
.bg-primary{
    color: @light;
    background-color: @primary;
}
a.bg-primary:hover{
    background-color: @primary-dark;
}
.bg-auxiliary{
    color: @light;
    background-color: @auxiliary;
}
a.bg-auxiliary:hover{
    background-color: @auxiliary-dark;
}
.bg-muted{
    background: @gray-lighter;
}
.bg-success{
    color: @light;
    background-color: @success;
}
a.bg-success:hover{
    background-color: @success-dark;
}
.bg-info{
    color: @light;
    background-color: @info;
}
a.bg-info:hover{
    background-color: @info-dark;
}
.bg-warning{
    color: @light;
    background-color: @warning;
}
a.bg-warning:hover{
    background-color: @warning-dark;
}
.bg-danger{
    color: @light;
    background-color: @danger;
}
a.bg-danger:hover{
    background-color: @danger-dark;
}
