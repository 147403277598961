.label{
    display: inline-block;
    font-size: @font-size;
    line-height: @line-height;
    margin:0 0.2em .2em 0;
    padding: 0 0.6em;

    cursor: default;
    user-select: none;

    color: @light;
    border: 1px solid;
    border-radius: @radius;
    background: @primary;
}
.label-sm{
    font-size: @font-size-sm;
    line-height: 1.6em;
}
.label-lg{
    font-size: @font-size-lg;
    line-height: 2em;
}
.label:first-child{
    margin-left: 0;
}

.label.label-auxiliary{
    background: @auxiliary;
}
.label.label-success{
    background: @success;
}
.label.label-info{
    background: @info;
}
.label.label-warning{
    background: @warning;
}
.label.label-danger{
    background: @danger;
}
.label.label-bordered{
    color: @primary;
    background: @light;
}
.label-bordered.label-auxiliary{
    color: @auxiliary;
}
.label-bordered.label-success{
    color: @success;
}
.label-bordered.label-info{
    color: @info;
}
.label-bordered.label-warning{
    color: @warning;
}
.label-bordered.label-danger{
    color: @danger;
}

.label-closable{
    cursor: pointer;
}

.label-closable .ion:last-child{
    font-size: 1em;
    margin-left: 0.4em;

    vertical-align: baseline;

    opacity: 0.7;
    transition: opacity ease .3s;
}

.label-closable.label-bordered{
    position: relative;
    padding-right: .5em;
}
.label-closable.label-bordered .ion:last-child{
	margin-left: 0.7em;
}
.label-closable.label-bordered .ion:last-child:after{
    position: absolute;
    top: 0;
    right: 1.9em;
    width: 0;
    height: 100%;

    content: '';

    border-left: 1px solid;
}
.label-closable:hover .ion{
    opacity: 1;
}
