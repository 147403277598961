.table{
    font-size: @font-size;
    width: 100%;
    max-width: 100%;

    border: 1px solid @border;
    border-radius: @radius;
    background: @light;
}
.table > thead > tr > th,.table > thead > tr > td,.table > tbody > tr > th,.table > tfoot > tr > th,.table > tbody > tr > td,.table > tfoot > tr > td{
    font-weight: normal;
    line-height: 1.42857143;
    height: @form-base-height + 16;
    padding: 0 18px;

    vertical-align: inherit;

    border-top: 1px solid @border;
}
.table > thead > tr > th,.table > thead > tr > td{
    font-weight: normal;
    line-height: @table-head-height;
    height: @table-head-height;
    padding-top: 0;
    padding-bottom: 0;

    vertical-align: bottom;

    background: @gray-lighter;
}
.table > tbody > tr:first-child > th,.table > tbody > tr:first-child > td{
    border-top: 0;
}
.table > caption + thead > tr:first-child > th,.table > colgroup + thead > tr:first-child > th,.table > thead:first-child > tr:first-child > th,.table > caption + thead > tr:first-child > td,.table > colgroup + thead > tr:first-child > td,.table > thead:first-child > tr:first-child > td{
    border-top: 0;
}
.table > tbody + tbody{
    border-top: 2px solid @border;
}
.table .table{
    background-color: #fff;
}
.table-condensed > tbody > tr > th,.table-condensed > tfoot > tr > th,.table-condensed > tbody > tr > td,.table-condensed > tfoot > tr > td{
    height: auto;
    padding-top: 5px;
    padding-bottom: 5px;
}
.table-bordered > thead > tr > th,.table-bordered > tbody > tr > th,.table-bordered > tfoot > tr > th,.table-bordered > thead > tr > td,.table-bordered > tbody > tr > td,.table-bordered > tfoot > tr > td{
    border-right: 1px solid @border;
}
.table-striped > tbody > tr:nth-of-type(even){
    background-color: @gray-lighter;
}
.table-hover > tbody > tr:hover,.table-tr-hover{
    background-color: @gray-lighter;
}
table col[class*='col-']{
    display: table-column;
    float: none;
    position: static;
}
table td[class*='col-'],table th[class*='col-']{
    display: table-cell;
    float: none;
    position: static;
}

.table-responsive{
    overflow-x: auto;
    min-height: 0.01%;
}
@media screen and (max-device-width: @Pad){
    .table-responsive{
        overflow-y: hidden;
        width: 100%;

        border: 1px solid @border;

        -ms-overflow-style: -ms-autohiding-scrollbar;
    }
    .table-responsive > .table{
        margin-bottom: 0;
    }
    .table-responsive > .table > thead > tr > th,.table-responsive > .table > tbody > tr > th,.table-responsive > .table > tfoot > tr > th,.table-responsive > .table > thead > tr > td,.table-responsive > .table > tbody > tr > td,.table-responsive > .table > tfoot > tr > td{
        white-space: nowrap;
    }
    .table-responsive > .table-bordered{
        border: 0;
    }
    .table-responsive > .table-bordered > thead > tr > th:first-child,.table-responsive > .table-bordered > tbody > tr > th:first-child,.table-responsive > .table-bordered > tfoot > tr > th:first-child,.table-responsive > .table-bordered > thead > tr > td:first-child,.table-responsive > .table-bordered > tbody > tr > td:first-child,.table-responsive > .table-bordered > tfoot > tr > td:first-child{
        border-left: 0;
    }
    .table-responsive > .table-bordered > thead > tr > th:last-child,.table-responsive > .table-bordered > tbody > tr > th:last-child,.table-responsive > .table-bordered > tfoot > tr > th:last-child,.table-responsive > .table-bordered > thead > tr > td:last-child,.table-responsive > .table-bordered > tbody > tr > td:last-child,.table-responsive > .table-bordered > tfoot > tr > td:last-child{
        border-right: 0;
    }
    .table-responsive > .table-bordered > tbody > tr:last-child > th,.table-responsive > .table-bordered > tfoot > tr:last-child > th,.table-responsive > .table-bordered > tbody > tr:last-child > td,.table-responsive > .table-bordered > tfoot > tr:last-child > td{
        border-bottom: 0;
    }
}
/* for table plugin */
.table-wrapper{
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    box-sizing: border-box;

    cursor: default;

    border: 1px solid @border;
    border-radius: @radius;
}
.table-wrapper .table > thead > tr > th,.table-wrapper .table > thead > tr > td,.table-wrapper .table > tbody > tr > th,.table-wrapper .table > tfoot > tr > th,.table-wrapper .table > tbody > tr > td,.table-wrapper .table > tfoot > tr > td{
    padding: 0;
}
.table-wrapper .table-cell{
    overflow: hidden;
    padding: 0 15px;

    white-space: normal;
    word-break: break-all;
}
.table-scroll-x{
    overflow-x: auto;
    overflow-y: hidden;
}
.table-header .table,.table-body .table{
    border: 0;
}
.table-body{
    overflow-x: hidden;
    overflow-y: auto;
}
.table-body .table .table-cell{
    line-height: @line-height;
}
.table-body .table .table-cell-dirty,.table-body .table .table-cell-editable{
    position: relative;
}
.table-body .table .table-cell-dirty:before{
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;

    content: '';

    border: 4px solid;
    border-color: @primary transparent transparent @primary;
}
.table-highlight,.table-hover > tbody > .table-highlight:hover{
    color: @light;
    background: @primary;
}
.table-sort, .table-filter{
    display: inline-block;
    overflow: hidden;
    position: relative;
    line-height: 1.4em;
    width: 1.4em;
    height: 1.6em;
    margin: -1px 0 0 4px;

    cursor: pointer;
    vertical-align: middle;

    color: #c3cbd6;
}
.table-sort .ion{
    display: block;
    overflow: hidden;
    position: absolute;
    font-size: 1.3em;
    line-height: 0.7em;
    height: 0.7em;

    transition: color 0.2s ease-in-out;
}
.table-sort .table-sort-up{
    top: 0;
}
.table-sort .table-sort-down{
    bottom: 0;
}
.table-sort .ion.on{
    color: @primary;
}
.table-fixed{
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    box-shadow: 2px 0 6px -2px rgba(0,0,0,0.2);
}
.table-fixed .table-header,.table-fixed .table-body{
    overflow: hidden;
}
.table-wrapper .table-cell-editer{
    position: absolute;
    z-index: 9;
    top: 0;
    line-height: @line-height;
    height: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
}
