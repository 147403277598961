*[class*="rect-"]{position: relative;display: block; width: 100%;height: 0;overflow: hidden;}
*[class*="rect-"] ._full{position: absolute;left: 0;top:0;width: 100%;height: 100%;
*height: auto;}
.rect-4854{padding-bottom: 48.54%;}
.rect-50{padding-bottom: 50%;}
.rect-5625{padding-bottom: 56.25%;}
.rect-60{padding-bottom: 60%;}
.rect-618{padding-bottom: 61.8%;}
.rect-70{padding-bottom: 70%;}
.rect-75{padding-bottom: 75%;}
.rect-80{padding-bottom: 80%;}
.rect-90{padding-bottom: 90%;}
.rect-100{padding-bottom: 100%;}