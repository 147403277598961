html{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body{
    overflow-x: hidden;
    font-family: @font-family;
    font-size: @font-size;
    line-height: @line-height;
    min-width: @view-width;

    color: @text-color;
    background: @light;
}
body,h1,h2,h3,h4,p,ul,ol,dd,dl,form{
    margin: 0;
}
hr{
    margin-top: 20px;
    margin-bottom: 20px;

    border: 0;
    border-top: 1px solid #eee;
}
img{
    max-width: 100%;
    height: auto;

    vertical-align: top;

    -ms-interpolation-mode: bicubic;
    border: 0 ;
}
ul{
    padding-left: 0;

    list-style-type: none;
}
i,em{
    font-style: normal;
}
big{
    font-size: @font-size-lg;
}
small{
    font-size: @font-size-sm;
}
a{
    -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
    text-decoration: none;

    color: @link-color;
    outline: 0;
}
a:hover{
    color: @link-color-hover;
}
article,aside,dialog,footer,header,section,nav,figure,menu,audio,canvas,progress,video{
    display: block;
}
input,select,textarea{
    font-family: inherit;
    font-size: 100%;

    outline: 0;
}
input[type='checkbox'],input[type='radio']{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
    padding: 0;
}
input[type='number']::-webkit-inner-spin-button,input[type='number']::-webkit-outer-spin-button{
    height: auto;
}
input[type='search']{
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;

    -webkit-appearance: textfield;
}
input[type='search']::-webkit-search-cancel-button,input[type='search']::-webkit-search-decoration{
    -webkit-appearance: none;
}
input[type='file']{
    display: block;
}
input[type='range']{
    display: block;
    width: 100%;
}
select[multiple],select[size]{
    height: auto;
}
input[type='file']:focus,input[type='radio']:focus,input[type='checkbox']:focus{
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}
input[type=submit]{
    cursor: pointer;
}
fieldset{
    min-width: 0;
    margin: 0;
    padding: 0;

    border: 0;
}
legend{
    display: block;
    font-size: @font-size-lg;
    font-weight: 700;
    line-height: 2.5;
    width: 100%;
    margin-bottom: 20px;
    padding: 0;

    color: #333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}
table{
    border-spacing: 0;

    background-color: transparent;
}
td,th{
    padding: 0;
}

@-ms-viewport{
    width: device-width;
}

::-moz-selection{
    color: @light;
    background: @selected;
}
::selection{
    color: @light;
    background: @selected;
}
::-webkit-input-placeholder{
    color: @placeholder;
}
.placeholder{
    color: @placeholder;
}
h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6{
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
    margin-bottom: 0.5em;

    color: inherit;
}

h1,.h1{
    font-size: 2.5em;
}

h2,.h2{
    font-size: 2em;
}

h3,.h3{
    font-size: 1.75em;
}

h4,.h4{
    font-size: 1.5em;
}

h5,.h5{
    font-size: 1.25em;
}

h6,.h6{
    font-size: 1em;
}
