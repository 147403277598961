.progress{
    display: block;
    position: relative;
    font-size: @font-size-sm;
}

.progress-show-info{
    padding-right: 3.5em;
}

.progress-inner{
    display: block;

    border-radius: 100px;
    background-color: @gray-light;
}

.progress-bg{
    overflow: hidden;
    position: relative;
    height: 10px;
    transition: all 0.2s linear;

    border-radius: 100px;
    background-color: @light;
}

.progress-text{
    position: absolute;
    top: 50%;
    right: 0;
    line-height: 1;
    width: 3em;
    height: 1em;
    margin-top: -0.5em;

    text-align: left;
}

.progress-active .progress-bg:before{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';
    animation: progress-active 2s ease-in-out infinite;

}

.progress-primary .progress-inner, .progress-auxiliary .progress-inner, .progress-info .progress-inner, .progress-success .progress-inner, 
.progress-warning .progress-inner, .progress-danger .progress-inner{
    background-color: @gray-lighter;
}
.progress-primary .progress-bg{
    background-color: @primary;
}
.progress-primary{
    color: @primary;
}
.progress-auxiliary .progress-bg{
    background-color: @auxiliary;
}
.progress-auxiliary{
    color: @auxiliary;
}
.progress-info .progress-bg{
    background-color: @info;
}
.progress-info{
    color: @info;
}
.progress-success .progress-bg{
    background-color: @success;
}
.progress-success{
    color: @success;
}
.progress-warning .progress-bg{
    background-color: @warning;
}
.progress-warning{
    color: @warning;
}
.progress-danger .progress-bg{
    background-color: @danger;
}
.progress-danger{
    color: @danger;
}

@keyframes progress-active{
    0%{
        width: 0;
        background: #fff;
        opacity: 0.3;
    }
    to{
        width: 100%;
        background: #fff;
        opacity: 0;
    }
}
