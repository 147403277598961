.spin-wrap{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: table;
    width: 100%;
    height: 100%;
    text-align: center;
    vertical-align: middle;
	font-size: @font-size;
}
.spin-main{
    display: table-cell;
    width: inherit;
    height: inherit;
    vertical-align: middle;
}
.spin-text{
    
}
.spin-text .ion{
    font-size: 2.5em;
}
.spin-icon-load{
    -webkit-animation: ani-spin-load 1s linear infinite;
            animation: ani-spin-load 1s linear infinite;
}
.spin-size-sm {
    font-size: @font-size-sm;
}
.spin-size-lg {
    font-size: @font-size-lg;
}
@-webkit-keyframes ani-spin-load{
    from{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }50%{
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes ani-spin-load{
    from{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }50%{
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }to{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
