.badge{
    display: inline-block;
    position: relative;
    line-height: 1;

    vertical-align: middle;
}

.badge-count{
    position: absolute;
    z-index: 90;
    top: -10px;
    right: -18px;
    font-size: 12px;
    line-height: 18px;
    min-width: 20px;
    height: 20px;
    padding: 0 6px;

    text-align: center;
    white-space: nowrap;

    color: @light;
    border: 0;
    border-radius: 10px;
    background: @danger;
    box-shadow: 0 0 0 1px @light;
}

.badge-dot{
    position: absolute;
    z-index: 90;
    top: -4px;
    right: -4px;
    width: 8px;
    height: 8px;

    transform-origin: 0 center;

    border-radius: 100%;
    background: @danger;
    box-shadow: 0 0 0 1px @light;
}

.badge-count-alone{
    display: block;
    position: relative;
    top: auto;
    right: 0;
}

.badge-primary .badge-count,.badge-primary .badge-dot{
    background: @primary;
}

.badge-auxiliary .badge-count,.badge-auxiliary .badge-dot{
    background: @auxiliary;
}

.badge-info .badge-count,.badge-info .badge-dot{
    background: @info;
}

.badge-success .badge-count,.badge-success .badge-dot{
    background: @success;
}

.badge-warning .badge-count,.badge-warning .badge-dot{
    background: @warning;
}