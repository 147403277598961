@menu-horizontal-height: round(@font-size * 4.2);

.menu-ui{
    display: block;
    overflow: hidden;
    position: relative;
    font-size: @font-size;

    user-select: none;

    border-radius: @radius;
}
.menu-item{
    display: block;
    position: relative;
    z-index: 1;

    cursor: pointer;
}
.menu-item > .ion,.menu-item-title > .ion{
    vertical-align: middle;
}
.menu-horizontal{
    line-height: @menu-horizontal-height;
    height: @menu-horizontal-height;
}
.menu-horizontal .menu-item,.menu-horizontal .menu-submenu{
    float: left;
    position: relative;
    z-index: 3;
    padding: 0 @space;

    cursor: pointer;
}
.menu-light{
    background: @light;
}
.menu-light .menu-item{
    border-bottom: 2px solid transparent;
}
.menu-light.menu-horizontal .menu-item,.menu-light.menu-horizontal .menu-submenu{
    line-height: @menu-horizontal-height - 2px;
    height: @menu-horizontal-height - 2px;
}
.menu-light.menu-horizontal .menu-item-active,.menu-light.menu-horizontal .menu-item:hover,.menu-light.menu-horizontal .menu-opened{
    color: @primary;
    border-bottom-color: @primary;
}
.menu-horizontal.menu-light:after,.menu-vertical.menu-light:after{
    display: block;
    position: absolute;
    bottom: 0;

    content: '';

    background: @border;
}
.menu-horizontal.menu-light:after{
    left: 0;
    width: 100%;
    height: 1px;
}
.menu-dark{
    background: @gray-dark;
}
.menu-dark.menu-horizontal .menu-item,.menu-dark.menu-horizontal .menu-submenu{
    color: @gray-light;
}
.menu-dark.menu-horizontal .menu-item-active,.menu-dark.menu-horizontal .menu-item:hover,.menu-dark.menu-horizontal .menu-opened,.menu-primary.menu-horizontal .menu-item{
    color: @light;
}
.menu-primary{
    background: @primary;
}
.menu-primary.menu-horizontal .menu-item-active,.menu-primary.menu-horizontal .menu-item:hover{
    background: @primary-dark;
}
.menu-vertical{
    overflow-y: auto;
    height: 100%;
}


.menu-vertical::-webkit-scrollbar{
    position: absolute;
    width: 10px;
    margin-left: -10px;

    -webkit-appearance: none;
}

.menu-vertical::-webkit-scrollbar-track{
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    background-color: #eee;
}

.menu-vertical::-webkit-scrollbar-thumb{
    height: 50px;

    border-width: 1px 2px;
    border-style: solid;
    border-color: transparent;
    background-color: #ccc;
    background-clip: content-box;
}

.menu-vertical.menu-dark::-webkit-scrollbar-track{
    border-right: 1px solid #2c3334;
    border-left: 1px solid #2c3334;
    background-color: #444d50;
}

.menu-vertical.menu-dark::-webkit-scrollbar-thumb{
    background-color: #212526;
}
.menu-vertical.menu-light:after{
    z-index: 1;
    top: 0;
    right: 0;
    width: 1px;
    height: 100%;
}
.menu-vertical .menu-item,.menu-vertical .menu-item-title{
    position: relative;
    z-index: 1;
    padding: @font-size * 0.8 @font-size * 1.7;

    cursor: pointer;
}
.menu-sub-group .menu-item{
    padding-top: @font-size * 0.4;
    padding-bottom: @font-size * 0.4;
}
.menu-vertical .menu-item:hover,.menu-vertical .menu-item-title:hover{
    background: @gray-lighter;
}
.menu-vertical .menu-submenu .menu-item{
    padding-left: @font-size * 2.8;
}
.menu-vertical .menu-sub-group{
    display: none;
}
.menu-vertical .menu-opened .menu-sub-group{
    display: block;
}
.menu-light.menu-vertical .menu-item{
    border-right: 2px solid transparent;
}
.menu-light.menu-vertical .menu-item:hover{
    color: @primary;
}
.menu-light.menu-vertical .menu-item-active{
    z-index: 2;

    color: @primary;
    border-right: 2px solid @primary;
}
.menu-dark.menu-vertical .menu-item,.menu-dark.menu-vertical .menu-item-title{
    color: @gray-light;
}
.menu-dark.menu-vertical .menu-item:hover,.menu-dark.menu-vertical .menu-item-title:hover{
    color: @light;
    background: inherit;
}
.menu-dark.menu-vertical .menu-item-active,.menu-dark.menu-vertical .menu-item-active:hover,.menu-dark.menu-vertical .menu-item-active a:hover{
    color: @light;
    background: @primary;
}
.menu-dark.menu-vertical .menu-opened{
    background: @gray-darker;
}
.menu-dark.menu-vertical .menu-opened .menu-item-title{
    color: @light;
    background: @gray-dark;
}
.menu-primary.menu-vertical .menu-item,.menu-primary.menu-vertical .menu-item-title{
    color: @gray-lighter;
}
.menu-primary.menu-vertical .menu-item:hover,.menu-primary.menu-vertical .menu-item-title:hover{
    color: @light;
    background: @primary-dark;
}
.menu-primary.menu-vertical .menu-sub-group .menu-item:hover{
    color: @primary-dark;
    background: inherit;
}
.menu-primary.menu-vertical .menu-item-active,.menu-primary.menu-vertical .menu-sub-group .menu-item-active,.menu-primary.menu-vertical .menu-sub-group .menu-item-active:hover,.menu-primary.menu-vertical .menu-item-active:hover{
    color: @light;
    background: @primary-dark;
}
.menu-primary.menu-vertical .menu-opened{
    background: lighten(@primary, 20%);
}
.menu-primary.menu-vertical .menu-opened .menu-item-title{
    color: @light;
    background: @primary;
}
.menu-sub-ion{
    font-size: 0.9em;
    margin: 0 0.5em;

    transition: transform 0.2s ease-in-out;
}
.menu-vertical .menu-sub-ion{
    float: right;
    position: relative;
    top: 4px;
    margin-right: 0;
}
.menu-opened .menu-sub-ion{
    transform: rotate(180deg);
}
