.wrap{
    width: @view-width;
    margin-right: auto;
    margin-left: auto;
}
.fix{
    *zoom: 1;
    *clear: both;
}
.fix:before,.fix:after{
    display: table;
    clear: both;

    content: '';
}
.oh{
    overflow: hidden;

    *zoom: 1;
}
.l{
    float: left;
}.r{
    float: right;
}
.dib{
    display: inline-block;

    *display: inline;
    *zoom: 1;
}
.pr{
    position: relative;
}.pa{
    position: absolute;
}
.tl{
    text-align: left;
}.tc{
    text-align: center;
}.tr{
    text-align: right;
}
.el{
    overflow: hidden;

    white-space: nowrap;
    text-overflow: ellipsis;
}
.justify{
    overflow: hidden;

    text-align: justify !important;
}
.justify:after{
    content: ' 88888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888888';
}
.hide{
    display: none;
}
.hidetext{
    font: 0/0 a;

    letter-spacing: -9px;
}
.arr{
    display: inline-block;
    overflow: hidden;
    width: 0;
    height: 0;
}
.opc0{
    opacity: 0 ;

    filter: alpha(opacity=0);
}

.scrollbar::-webkit-scrollbar{
    position: absolute;
    width: 10px;
    margin-left: -10px;

    -webkit-appearance: none;
}
.scrollbar::-webkit-scrollbar-track{
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    background-color: #eee;
}
.scrollbar::-webkit-scrollbar-thumb{
    height: 50px;

    border-width: 1px 2px;
    border-style: solid;
    border-color: transparent;
    background-color: #ccc;
    background-clip: content-box;
}
.scrollbar-dark::-webkit-scrollbar-track{
    border-right: 1px solid #2c3334;
    border-left: 1px solid #2c3334;
    background-color: #444d50;
}
.scrollbar-dark::-webkit-scrollbar-thumb{
    background-color: #212526;
}
