.flex-col {overflow: hidden; display: -webkit-box; display: -webkit-flex; display: -ms-box; 
	display: -ms-flexbox; display: flex; flex-direction: column;
	-webkit-box-orient: vertical; -webkit-flex-direction: column; 
	-ms-flex-direction: column; }
.flex-row {overflow: hidden; display: -webkit-box; display: -webkit-flex; display: -ms-box; 
	display: -ms-flexbox; display: flex; -webkit-box-orient: horizontal; 
	-ms-flex-direction: row;}
.flex-1 { -webkit-box-flex: 1; -webkit-flex: 1; -ms-flex: 1; flex: 1;}
.flex-2 { -webkit-box-flex: 2; -webkit-flex: 2; -ms-flex: 2; flex: 2;}
.flex-3 { -webkit-box-flex: 3; -webkit-flex: 3; -ms-flex: 3; flex: 3;}
.flex-4 { -webkit-box-flex: 4; -webkit-flex: 4; -ms-flex: 4; flex: 4;}
.align-stretch { -webkit-box-align: stretch; -webkit-align-items: stretch; 
	-ms-align-items: stretch; -ms-flex-align: stretch; align-items: stretch;}
.align-center { -webkit-box-align: center; -webkit-align-items: center; 
	-ms-align-items: center; -ms-flex-align: center; align-items: center;}
.justify-center { -webkit-box-pack: center; -ms-box-pack: center; 
	-webkit-justify-content: center; -ms-flex-pack: center; justify-content: center;}
.flex-wrap { -webkit-flex-wrap: wrap; -ms-flex-wrap: wrap; flex-wrap: wrap;}